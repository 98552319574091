<template>
	<v-card elevation="0" class="min-height-screen h-full">
		<div class="d-flex justify-center align-center w-full h-full">
			<loader />
		</div>
	</v-card>
</template>

<script>
import Loader from '@/components/Loader.vue'

export default {
	name: 'Unauthorized',
	components: {
		Loader,
	},
}
</script>
